import ShopifySMS from "../../../assets/img/ShopifySMS.webp";
import WooCommerceBanner from "../../../assets/img/banner-772x250.png";
export default {
  name: "Market Place",
  data() {
    return {
      products: [{
        banner: ShopifySMS,
        title: "BMS SMS Notification",
        description: "",
        link: "https://apps.shopify.com/mnotify-bms-sms-notification?show_store_picker=1&st_source=autocomplete",
        banner_title: "Effortlessly create & send customized SMS to buyers using mNotify BMS SMS Notification",
        tags: ["Merchant Order Notification", "Customizable Templates"]
      }, {
        banner: WooCommerceBanner,
        title: "mNotifySMS WooCommerce",
        description: "",
        link: "https://wordpress.com/plugins/mnotifysms-for-woo",
        banner_title: "Send SMS notification to both buyer and seller related to order status information in WooCommerce.",
        tags: ["Order Notification", "SMS"]
      }]
    };
  }
};