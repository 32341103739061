var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "main-container",
    staticStyle: {
      "height": "100%",
      "overflow-y": "auto"
    }
  }, [_c('header', [_c('div', {
    staticClass: "left-side"
  }, [_c('h1', {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("Try the official BMS Apps")]), _c('p', [_vm._v("Integrate BMS directly into your workflow")]), _c('el-button', {
    attrs: {
      "icon": "el-icon-bottom"
    }
  }, [_vm._v("See Apps Below")])], 1), _c('div', {
    staticClass: "right-side"
  }, [_c('div', {
    staticClass: "grid-container"
  }, [_c('div', {
    staticClass: "grid-item shopify"
  }, [_c('el-card', {
    staticClass: "product-card"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/img/shopify_glyph.png"),
      "alt": "Shopify logo",
      "width": "100"
    }
  })])], 1), _c('div', {
    staticClass: "grid-item woo-commerce"
  }, [_c('el-card', {
    staticClass: "product-card",
    attrs: {
      "body-style": {
        padding: '0px'
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/img/Woo-logo-color.png"),
      "alt": "Woocommerce logo",
      "width": "200"
    }
  })])], 1), _c('div', {
    staticClass: "grid-item excel"
  }, [_c('el-card', {
    staticClass: "product-card",
    staticStyle: {
      "border": "none"
    },
    attrs: {
      "shadow": "never"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/img/Microsoft_Office_Excel.svg"),
      "alt": "Woocommerce logo",
      "width": "100"
    }
  })]), _vm._m(0)], 1)])])]), _c('div', {
    staticClass: "main-container"
  }, [_c('h5', {
    staticStyle: {
      "margin-top": "2rem",
      "font-weight": "bold"
    }
  }, [_vm._v("Available Apps")]), _c('el-divider'), _c('div', {
    staticClass: "products-container"
  }, _vm._l(_vm.products, function (product, index) {
    return _c('div', {
      key: index,
      staticClass: "grid-item-product"
    }, [_c('el-card', {
      staticClass: "available-card"
    }, [_c('img', {
      attrs: {
        "src": product.banner,
        "alt": "App banner",
        "width": "100"
      }
    }), _c('div', {
      staticClass: "card-content",
      staticStyle: {
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center"
      }
    }, [_c('div', {
      staticStyle: {
        "margin-block": "1rem"
      }
    }, [_c('h5', {
      staticClass: "available-product-text",
      staticStyle: {
        "margin-top": "0.8rem"
      }
    }, [_vm._v(" " + _vm._s(product.title) + " ")]), _c('div', {
      staticStyle: {
        "display": "flex",
        "gap": "1rem",
        "flex-wrap": "wrap"
      }
    }, _vm._l(product.tags, function (tag, index) {
      return _c('el-tag', {
        key: index,
        attrs: {
          "size": "mini",
          "type": "info"
        }
      }, [_vm._v(_vm._s(tag))]);
    }), 1)]), _c('a', {
      staticStyle: {
        "text-decoration": "none"
      },
      attrs: {
        "href": product.link,
        "target": "_blank"
      }
    }, [_c('el-button', {
      attrs: {
        "type": "danger",
        "icon": "el-icon-circle-plus",
        "size": "small",
        "round": ""
      }
    }, [_vm._v("Install Add-on")])], 1)]), _c('el-collapse', [product.title == 'BMS SMS Notification' ? _c('el-collapse-item', {
      attrs: {
        "title": product.banner_title,
        "name": "1"
      }
    }, [_c('div', [_c('p', [_vm._v(" Introducing \"mNotify BMS SMS Notification\" - the unique solution for simplified SMS communication. Easily send customized SMS notifications to clients and yourself. Solve the problem of limited SMS coverage in regions like Africa. Expand your reach and connect with customers worldwide. Enjoy seamless setup and usage. Craft personalized SMS templates to deepen relationships, drive sales, and enhance satisfaction. Experience the power of \"mNotify BMS\" to streamline communication, overcome barriers. ")]), _c('ul', [_c('li', [_vm._v("Customized SMS templates.")]), _c('li', [_vm._v("Send messages on different events.")]), _c('li', [_vm._v("Seamless Setup and Usage.")])])])]) : product.title == 'mNotifySMS WooCommerce' ? _c('el-collapse-item', {
      attrs: {
        "title": product.banner_title,
        "name": "1"
      }
    }, [_c('p', [_vm._v(" Our extension will integrate SMS features into your store at no charge. mNotifySMS allows you to simply notify the customer through text messages with important order status information from your store instantly. Configuration is very easy, no need to setup any SMS gateway. Purchase SMS credits will be given upon registration. ")]), _c('br'), _c('h5', [_vm._v("Features:")]), _c('ul', [_c('li', [_vm._v("Notify seller whenever a new order is placed.")]), _c('li', [_vm._v("Inform buyer the current order status.")]), _c('li', [_vm._v("Support all woocomerce order statuses.")]), _c('li', [_vm._v("Customizable sms/message templates.")]), _c('li', [_vm._v(" These tags are supported to customize message: [shop_name], [order_id], [order_amount], [order_status], [order_product], [payment_method], [bank_details], [billing_first_name], [billing_last_name], [billing_phone], [billing_email], [billing_company], [billing_address], [billing_country], [billing_city], [billing_state], [billing_postcode] ")]), _c('li', [_vm._v(" Custom checkout field added from Woo Checkout Field Editor Pro is supported. ")])])]) : _vm._e()], 1)], 1)], 1);
  }), 0)], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticClass: "product-text",
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v(" Excel Integration "), _c('br'), _c('small', [_vm._v("Coming soon...")])]);
}];
export { render, staticRenderFns };